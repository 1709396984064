import React from "react";
import noise from '../images/grain.png';
import { BsInstagram } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { BsGithub} from 'react-icons/bs';

class FooterSection extends React.Component {
    render() {
        return <>
            <div
                className="footer-section"
                style={{ backgroundImage: `url(${noise})` }}
            >
                <p className="footer-text sect-l">
                    <a href="https://www.instagram.com/digitalbando/"><BsInstagram /></a>
                    <a href="https://facebook.com/digitalbando"><BsFacebook /></a>
                    <a href="https://github.com/noahshotz"><BsGithub /></a>
                </p>
                <p className="footer-text sect-r">
                    <a href="#">imprint</a>
                    <a href="#">privacy</a>
                </p>
            </div>
        </>;
    }
}

export default FooterSection;