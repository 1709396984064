import React from "react";
import LandingImage from './LandingImage';
import noise from '../images/grain.png';

class LandingSection extends React.Component {
    render() {
        return <>
        <div disabled
        className="landing-section"
        style={{ backgroundImage: `url(${noise})` }}
        >
            <LandingImage />
        </div>
        </>
    }
}
  
export default LandingSection;