import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import LandingSection from "./components/LandingSection";
import AboutSection from "./components/AboutSection";
import TechSection from './components/TechSection';
import FooterSection from "./components/FooterSection";
import Toggle from './components/Toggle';

import "./index.scss";

import { GlobalStyles } from './global';
import { useDarkMode } from './useDarkMode';
import { lighttheme, darktheme } from "./components/theme";

function App() {

  // set page title
  const [title, setTitle] = useState("digital bando © 2022");
  useEffect(() => {
    document.title = title;
  }, [title]);

  const changeTitle = (event) => {
    setTitle(event.target.value);
  };

  // dark theme state
  const [theme, toggleTheme, componentMounted] = useDarkMode();
  const themeMode = theme === 'light' ? lighttheme : darktheme;

  if (!componentMounted) {
    return <div />
  };

  return (
    <ThemeProvider theme={themeMode}>
      <div className="App">
        <GlobalStyles />
        <Toggle theme={theme} toggleTheme={toggleTheme} />
        <LandingSection />
        <AboutSection />
        <TechSection />
        <FooterSection />
      </div>
    </ThemeProvider>
  );
}

export default App;
