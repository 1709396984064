import React from "react";
import bgLogoDark from '../images/db-eyes-dark.png';
import bgLogoLight from '../images/db-eyes-light.png';


class LandingImage extends React.Component {
  render() {
    return <>
      <img className="landing-image landing-image-dark" src={bgLogoDark} alt="digitalbando"/>
      <img className="landing-image landing-image-light" src={bgLogoLight} alt="digitalbando"/>
    </>;
  }
}

export default LandingImage;