import React from 'react'
import { func, string } from 'prop-types';
import { MdLightMode } from 'react-icons/md';
import { MdNightlightRound } from 'react-icons/md';

const Toggle = ({ theme, toggleTheme }) => {
  const isLight = theme === 'light';

  return (
    <a className="mode-toggle" lighttheme={isLight} onClick={toggleTheme} >
        {isLight ? (
          <MdLightMode />
        ) : (
          <MdNightlightRound />
        )}
    </a>
  );
};

Toggle.propTypes = {
  toggleTheme: func.isRequired,
  theme: string.isRequired,
}

export default Toggle;