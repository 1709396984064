import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .landing-section {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  .about-section {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  .tech-section {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  .footer-section {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  p {
    color: ${({ theme }) => theme.text};
  }
  a {
    color: ${({ theme }) => theme.text};
  }
  .landing-image-dark {
    display: ${({ theme }) => theme.landingimagedark};
  }
  .landing-image-light {
    display: ${({ theme }) => theme.landingimagelight};
  }
  .mode-toggle {
    background-color: ${({ theme }) => theme.body};
  }
  
`;