export const lighttheme = {
  body: '#F9F9F9',
  text: '#0a0a0a',
  landingimagedark: 'none',
  landingimagelight: 'initial'
}

export const darktheme = {
  body: '#0a0a0a',
  text: '#F9F9F9',
  landingimagedark: 'initial',
  landingimagelight: 'none'
}