import React from "react";
import noise from '../images/grain.png';
import { DiReact } from "react-icons/di";
import { DiHtml5 } from "react-icons/di";
import { DiCss3 } from "react-icons/di";
import { DiJava } from "react-icons/di";
import { DiPhp } from "react-icons/di";
import { DiMysql } from "react-icons/di";
import { DiPython } from "react-icons/di";
import { GrNode } from "react-icons/gr";

class TechSection extends React.Component {
    render() {
        return <>
            <div
                className="tech-section"
                style={{ backgroundImage: `url(${noise})` }}
            >
                <div className="tech-container">
                    <h2 className="section-heading">tech stack</h2>
                    <p className="tech-text no-select">
                        <a href="#"><DiPhp /></a>
                        <a href="#"><DiMysql /></a>
                        <a href="#"><DiReact /></a>
                        <a href="#"><GrNode /></a>
                        <a href="#"><DiHtml5 /></a>
                        <a href="#"><DiCss3 /></a>
                        <a href="#"><DiPython /></a>
                        <a href="#"><DiJava /></a>
                    </p>
                </div>
            </div>
        </>;
    }
}

export default TechSection;