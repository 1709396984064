import React from "react";
import noise from '../images/grain.png';

class AboutSection extends React.Component {
    render() {
        return <>
            <div
                className="about-section"
                style={{ backgroundImage: `url(${noise})` }}
            >
                <p className="text-center no-select">
                    digital bando builds 🦾 future-proof 👽 digital systems & 🛠️ infrastructure. as a  multi-disciplinary ✨ design studio and 🤝 consultancy firm based in 🍻 berlin -
                    germany, we serve 🕷 international customers 🌍 in the areas of{" "}
                    <a href="#">💻 web development</a>, <a href="#">💽 back end systems</a>,{" "}
                    <a href="#">🏗️ data engineering</a>, <a href="#">🚀 real time applications</a>
                    , <a href="#">👾augmented reality & 3d</a>.<br />
                    <a href="mailto:info@digitalbando.com" className="btn">💭 Get in touch.</a>
                </p>
            </div>
        </>;
    }
}

export default AboutSection;